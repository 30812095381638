import { useState } from "react";
import '../styles/services.css';

const Services = () => {
    const [type, setType] = useState(null);

    const handleClick = (e) => {
        if(e.classList.contains('info')){
            e.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            e.classList.remove('info');
        }
        else{
            e.scrollTo({
                top: e.scrollHeight,
                behavior: 'smooth'
            });
            e.classList.add('info');
        }
    }

    const serviceTiles = () => {
        switch(type){
            case 'residential':
                return(
                <>
                <button className='service-toggle-btn' onClick={() => setType('commercial')}>Switch to Commercial</button>
                <div className='s-tile-cont'>
                    <div className='s-tile' onClick={(e) => handleClick(e.currentTarget)}>
                        <h1>Roof Installation</h1>
                        <p>A complete roof installation involves removing the old roofing materials (if necessary) and installing a new roof tailored to the homeowner's preferences. This service includes selecting the right roofing materials, such as shingles, tiles, or metal, and ensuring the roof is weather-resistant, properly insulated, and meets local building codes.</p>
                    </div>
                    <div className='s-tile' onClick={(e) => handleClick(e.currentTarget)}>
                        <h1>Roof Repair</h1>
                        <p>Roof repair addresses specific issues, such as leaks, missing shingles, or damaged flashing. This service is ideal for minor problems that don’t require a full roof replacement, helping homeowners extend the life of their roof and maintain its integrity.</p>
                    </div>
                    <div className='s-tile' onClick={(e) => handleClick(e.currentTarget)}>
                        <h1>Roof Replacement</h1>
                        <p>When a roof is severely damaged or has reached the end of its lifespan, a full replacement is necessary. This service involves completely removing the old roof, inspecting the underlying structure for damage, and installing a brand-new roof to enhance durability and curb appeal.</p>
                    </div>
                    <div className='s-tile' onClick={(e) => handleClick(e.currentTarget)}>
                        <h1>Roof Inspection</h1>
                        <p>A professional roof inspection evaluates the condition of a roof, identifying potential issues like water damage, structural concerns, or wear and tear. Regular inspections help homeowners prevent costly repairs by catching problems early and ensuring the roof remains in good condition.</p>
                    </div>
                    <div className='s-tile' onClick={(e) => handleClick(e.currentTarget)}>
                        <h1>Roof Maintenance</h1>
                        <p>Roof maintenance includes cleaning, sealing, and making minor repairs to keep the roof in optimal condition. This service can prevent issues like mold, moss growth, and premature wear, saving homeowners money in the long run.</p>
                    </div>
                    <div className='s-tile' onClick={(e) => handleClick(e.currentTarget)}>
                        <h1>Gutter Installation & Repair</h1>
                        <p>Properly functioning gutters are essential for directing water away from a home’s foundation. This service involves installing or repairing gutters and downspouts to ensure effective water management, protecting the roof and structure from water damage.</p>
                    </div>
                    <div className='s-tile' onClick={(e) => handleClick(e.currentTarget)}>
                        <h1>Emergency Roofing Services</h1>
                        <p>Storms, fallen trees, or other unexpected events can cause severe roof damage that requires immediate attention. Emergency roofing services provide rapid repairs or temporary solutions to prevent further damage until permanent repairs can be made.</p>
                    </div>
                    <div className='s-tile' onClick={(e) => handleClick(e.currentTarget)}>
                        <h1>Skylight Installation and Repair</h1>
                        <p>Adding or repairing skylights enhances natural lighting and ventilation in a home. This service ensures that skylights are properly installed or resealed, preventing leaks and maintaining energy efficiency.</p>
                    </div>
                </div>
                </>
            );
            case 'commercial':
                return(
                    <>
                    <button className='service-toggle-btn' onClick={() => setType('residential')}>Switch to Residential</button>
                    <div className='s-tile-cont'>
                        <div className='s-tile commercial-tile' onClick={(e) => handleClick(e.currentTarget)}>
                            <h1>Flat Roof Installation</h1>
                            <p>Flat roofs are common in commercial buildings due to their cost-efficiency and ease of maintenance. This service involves installing materials like TPO, EPDM, or PVC membranes to provide a durable, weather-resistant, and energy-efficient roofing solution.</p>
                        </div>
                        <div className='s-tile commercial-tile' onClick={(e) => handleClick(e.currentTarget)}>
                            <h1>Metal Roof Installation</h1>
                            <p>Metal roofs are highly durable, energy-efficient, and low-maintenance, making them a popular choice for commercial buildings. This service includes installing metal panels or shingles designed to withstand harsh weather conditions and provide long-lasting protection.</p>
                        </div>
                        <div className='s-tile commercial-tile' onClick={(e) => handleClick(e.currentTarget)}>
                            <h1>Roof Coatings</h1>
                            <p>Roof coatings, such as silicone or acrylic, are applied to extend the lifespan of a commercial roof. They protect against UV rays, water damage, and leaks while improving energy efficiency by reflecting sunlight and reducing cooling costs.</p>
                        </div>
                        <div className='s-tile commercial-tile' onClick={(e) => handleClick(e.currentTarget)}>
                            <h1>Commercial Roof Repair</h1>
                            <p>This service focuses on fixing leaks, damaged flashing, or punctures in commercial roofing systems. Repair services help businesses avoid costly replacements by addressing issues promptly and ensuring the roof remains structurally sound.</p>
                        </div>
                        <div className='s-tile commercial-tile' onClick={(e) => handleClick(e.currentTarget)}>
                            <h1>Commercial Roof Replacement</h1>
                            <p>When a commercial roof is beyond repair, a full replacement is necessary. This service involves removing the old roofing system and installing a new one tailored to the building’s needs, ensuring it meets industry standards and local regulations.</p>
                        </div>
                        <div className='s-tile commercial-tile' onClick={(e) => handleClick(e.currentTarget)}>
                            <h1>BUR Systems</h1>
                            <p>BUR systems consist of multiple layers of asphalt and reinforced fabrics to create a durable, waterproof membrane. This service is ideal for flat or low-slope commercial roofs, offering reliable protection and longevity.</p>
                        </div>
                        <div className='s-tile commercial-tile' onClick={(e) => handleClick(e.currentTarget)}>
                            <h1>Green Roofing Solutions</h1>
                            <p>Green roofs involve installing vegetation and a waterproofing system on a building's roof. This service is increasingly popular for businesses looking to enhance sustainability, improve insulation, and reduce energy costs.</p>
                        </div>
                        <div className='s-tile commercial-tile' onClick={(e) => handleClick(e.currentTarget)}>
                            <h1>Commercial Roof Maintenance</h1>
                            <p>Regular maintenance, including inspections, cleaning, and minor repairs, ensures the long-term performance of commercial roofs. This service helps prevent costly repairs or replacements by addressing issues before they escalate.</p>
                        </div>
                    </div>
                    </>
                );
            default:
                return(
                <div className='split-btn'>
                    <h2>What type of services are you looking for?</h2>
                    <button onClick={() => setType('residential')}>Residential</button>
                    <button onClick={() => setType('commercial')}>Commercial</button>
                </div>
            );
        }
    }
    return(
        <div className='service-wrapper'>
            {serviceTiles()}
        </div>
    )
}

export default Services;