import { useEffect, useRef, useState } from "react";
import { useScroll } from "../context/ScrollContext";

const Main = () => {
    const publicUrl = process.env.PUBLIC_URL;
    const scrollDirection = useScroll();

    const [scrollIndex, setScrollIndex] = useState(0);
    const [opacity, setOpacity] = useState(1); // State to control opacity

    const heroWhite = useRef(null);
    const heroRed = useRef(null);
    const secondWhite = useRef(null);
    const secondRed = useRef(null);
    const serviceOverview = useRef(null);
    const reviews = useRef(null);
    const footer = useRef(null);

    useEffect(() => {
        if (scrollDirection) {
            console.log(scrollDirection);
            
            if (scrollDirection === 'down') {
                setScrollIndex((prevIndex) => Math.min(prevIndex + 1, 4));
            } else if (scrollDirection === 'up') {
                setScrollIndex((prevIndex) => Math.max(prevIndex - 1, 0));
            }
        }
    }, [scrollDirection]);

    useEffect(() => {
        // Set opacity to 0
        if((scrollIndex < 3) || (scrollIndex === 3 && scrollDirection === 'down')){
            setOpacity(0);
        }

        // Set a timeout to revert opacity back to 1 after 0.75 seconds
        const timer = setTimeout(() => {
            setOpacity(1);
        }, 750); // 750ms delay

        // Clean up the timer on component unmount or when scrollIndex changes
        return () => clearTimeout(timer);
    }, [scrollIndex]);

    useEffect(() => {
        if (heroWhite.current && heroRed.current) {
            switch (scrollIndex) {
                case 0:
                    secondWhite.current.style.top = '100vh';
                    secondRed.current.style.top = '100vh';
                    break;
                case 1:        
                    secondWhite.current.style.top = '0';
                    secondRed.current.style.top = '0';
                    serviceOverview.current.style.right = '100vw';
                    break;
                case 2:
                    serviceOverview.current.style.right = '0';
                    reviews.current.style.top = '100vh';
                    break;
                case 3:
                    reviews.current.style.top = '0';
                    footer.current.style.top = '100vh';
                    break;
                case 4:
                    reviews.current.style.top = '-30vh';
                    footer.current.style.top = '70vh';
                    break;
                default:
                    break;
            }
        }
    }, [scrollIndex]);

    return (
        <div className='main-content' style={{backgroundImage: 'url('+publicUrl+'/img/hero.png)'}}>
            <div className='hero-white' ref={heroWhite}>
                <div className='content' style={{opacity: opacity}}>
                    <h3>YOUR GO-TO EXPERTS AND TRUSTED CONTRACTORS IN HOUSTON TEXAS</h3>
                    <div className='line'/>
                    <p>We’re proud to serve the Houston, Texas community with expert roofing solutions you can trust. From residential repairs to full-scale commercial installations, our experienced team is dedicated to providing reliable service with quality craftsmanship. Whether you're looking for a roof inspection or a complete upgrade, we’re here to protect your property and ensure lasting results.</p>
                </div>
            </div>
            <div className='hero-red' ref={heroRed}>
                <div className='content' style={{opacity: opacity}}>
                    <p style={{width: '100%', textAlign: 'right', color: '#ffffff'}}>SCROLL TO LEARN MORE</p>
                </div>
            </div>
            <div className='second-white' ref={secondWhite}>
                <div className='content' style={{opacity: opacity}}>
                    <div className='img' style={{backgroundImage: 'url('+publicUrl+'/img/secondA.jpg)'}}/>
                    <div className='img' style={{backgroundImage: 'url('+publicUrl+'/img/secondB.jpg)'}}/>
                </div>
            </div>
            <div className='second-red' ref={secondRed}>
                <div className='content' style={{opacity: opacity}}>
                    <h3 style={{color: '#ffffff'}}>COMPREHENSIVE ROOFING SOLUTIONS</h3>
                    <p style={{color: '#ffffff'}}>Whether it’s a small repair or a complete roof replacement, we offer a wide range of services for both residential and commercial properties. Our experienced team uses high-quality materials and proven techniques to ensure each project is done right the first time.</p>
                    <div className='white-line'/>
                    <h3 style={{color: '#ffffff'}}>LOCAL EXPERTS</h3>
                    <p style={{color: '#ffffff'}}>As a Houston-based company, we know how to handle the region’s unique weather conditions. From heavy rain to extreme heat, our roofing solutions are designed to withstand the elements and keep your home or business protected year-round.</p>
                    <div className='white-line'/>
                    <h3 style={{color: '#ffffff'}}>CUSTOMER-FIRST EXPERIENCE</h3>
                    <p style={{color: '#ffffff'}}>Your satisfaction is our priority. We provide clear communication, detailed estimates, and tailor-made roofing options that fit your needs and budget. From start to finish, we’re here to guide you through the process and deliver results you’ll feel good about.</p>
                </div>
            </div>
            <div className='service-overview' ref={serviceOverview}>
                <div className='content' style={{opacity: opacity}}>
                    <div className='row'>
                        <div className='column'>
                            <div className='s-img-a' style={{backgroundImage: `url(${publicUrl}/img/mainCom.jpg)`}}/>
                            <div className='service-text'>
                                <h1>COMMERCIAL</h1>
                                <p>Businesses need strong, reliable roofing to protect their assets, employees, and customers. At HTEX Roofing, we offer a full range of commercial roofing services, from repairs and maintenance to complete system installations. We work with flat roofs, metal roofs, and other commercial-grade materials, providing long-lasting protection for warehouses, office buildings, retail spaces, and more. No matter the size or complexity of the project, we ensure your business is covered.</p>
                            </div>
                        </div>
                        <div className='column'>
                            <div className='service-text'>
                                <h1>RESIDENTIAL</h1>
                                <p>Our residential roofing services include everything from routine maintenance and small repairs to complete roof replacements. We work with a variety of materials, including shingles, tile, and metal, ensuring that your roof is both durable and visually appealing. Whether you're dealing with storm damage or simply need an upgrade, we’re here to provide solutions tailored to your home’s unique needs.</p>
                            </div>
                            <div className='s-img-b' style={{backgroundImage: `url(${publicUrl}/img/mainRes.jpg)`}}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='reviews' ref={reviews}>
                <div className='content' style={{opacity: opacity}}>
                    <div className='row'>
                        <div className='column'>
                            <img src={publicUrl+'/img/star.png'}/>
                            <h4>WHAT OUR CUSTOMERS SAY ABOUT US</h4>
                        </div>
                        <div className='column'>
                            <div className='review-tile'>
                                <h5>Sylvia R</h5>
                                <p>Awsome service great customer service I will be keeping them as my go to roofers. Flexible payments and was able to come do the repair at a time that was good for me. 6 of 5 stars!</p>
                            </div>
                            <div className='white-line'/>
                            <div className='review-tile'>
                                <h5>Tony F</h5>
                                <p>Great worked preformed reasonable pricing and same day service</p>
                            </div>
                            <div className='white-line'/>
                            <div className='review-tile'>
                                <h5>Richard H</h5>
                                <p>They came to give me an estimate on a few shingle repairs after Hurricane Beryl. They took photos of everything they could find with a drone which was pretty neat while one of them also went up on the roof to get a better look. They showed me photos of everything they saw. I thought the pricing was decent and gave them the go ahead. They worked quickly and before the end of the day my roof was all patched up. No leaks during these recent patches of rain so money well spent in my opinion. There were some cheaper options but they either looked a bit sketchy or couldn’t do it until the end of the week. Overall pretty glad I hired these guys to repair my roof.</p>
                            </div>
                            <div className='white-line'/>
                            <div className='review-tile'>
                                <h5>James R</h5>
                                <p>I am thoroughly impressed with the exceptional service provided by Htex Roofing for my asphalt shingle full tear down project. From the initial consultation to the final installation, their team was professional, courteous, and meticulous in their work. The crew was efficient, clean, and respectful of my property throughout the entire process. The new roof looks fantastic and I’m so pleased with the quality of the materials and workmanship. Their competitive pricing and prompt communication made the experience seamless from start to finish. I highly recommend Htex Roofing to anyone in need of a reliable and trustworthy roofing company - 5 stars is not enough, they deserve 10!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer' ref={footer}>
                <div className='row footer-row'>
                    <img src={publicUrl+'/img/logo-white.png'}/>
                    <ul>
                        <li>HOME</li>
                        <li>ABOUT US</li>
                        <li>SERVICES</li>
                        <li>CONTACT</li>
                    </ul>
                </div>
                <div className='white-line'/>
                <div className='row link-row'>
                    <ul>
                        <h4>SOCIAL MEDIA</h4>
                        <li>Angi</li>
                        <li>Thumbtack</li>
                        <li>Nextdoor</li>
                        <li>Instagram</li>
                    </ul>
                    <ul>
                        <h4>LEGAL</h4>
                        <li>Privacy Policy</li>
                        <li>Terms of Service</li>
                        <li>HTEX Roofing ©2024</li>
                    </ul>
                    <ul>
                        <h4>CONTACT</h4>
                        <li>(281) 870 3303</li>
                        <li>contact@htexroofing.com</li>
                    </ul>
                </div>
                <div className='white-line'/>
                <p style={{color: '#ffffff', textAlign: 'right', width: '100%'}}><span style={{opacity: '0.25'}}>Website Built By</span> Eclipse Pixel</p>
            </div>
        </div>
    );
};

export default Main;
