import { useEffect, useState } from 'react';
import './App.css';
import About from './components/About';
import Main from './components/Main';
import Nav from './components/Nav';
import Services from './components/Services';
import Contact from './components/Contact';

function App() {

  const [page, setPage] = useState('home');

  useEffect(() => {console.log(page)}, [page])

  const changePage = (e) => {
    setPage(e);
  }

  const currPg = () => {
    switch(page){
      case 'home':
        return(<Main/>);
      case 'about':
        return(<About/>);
      case 'services':
        return(<Services/>);
      case 'contact':
        return(<Contact/>);
      default:
        return('error')
    }
  }

  return (
    <div className="App">
      <Nav setPage={changePage}/>
      {currPg()}
    </div>
  );
}

export default App;
