import { useEffect, useState } from 'react';
import '../styles/about.css';

const About = () => {

    const publicUrl = process.env.PUBLIC_URL;
    const [content, setContent] = useState(0);

    useEffect(() => {
        let dynBtn = document.getElementsByClassName('dyn-btn');
        dynBtn[0].style.opacity = '0.25';
        dynBtn[1].style.opacity = '0.25';
        dynBtn[2].style.opacity = '0.25';
        dynBtn[3].style.opacity = '0.25';
        dynBtn[4].style.opacity = '0.25';
        dynBtn[content].style.opacity = '1';
    }, [content])

    const dynamic = () => {
        switch(content){
            case 0:
                return(
                    <div className='dynamic-content'>
                        <img src={publicUrl+'/img/hero.png'} alt='dynamic=content-image'/>
                        <p>At HTEX Roofing, our mission is to provide the Houston community with reliable, high-quality roofing solutions tailored to meet your needs and budget. We believe that a roof is more than just a structure—it’s protection for everything you value. That’s why our focus is on delivering outstanding craftsmanship and complete customer satisfaction in every project we undertake, regardless of scope or scale.</p>
                    </div>
                );
            case 1:
                return(
                    <div className='dynamic-content'>
                        <img src={publicUrl+'/img/hero.png'} alt='dynamic=content-image'/>
                        <p>Your experience is just as important as the quality of our work. From your first consultation to project completion, we prioritize transparency and communication. Our team listens closely to your requirements and budget constraints, offering guidance to find the best solutions for your unique situation. At HTEX Roofing, we strive to make the process as straightforward and stress-free as possible, ensuring that you’re informed and confident every step of the way.</p>
                    </div>
                );
            case 2:
                return(
                    <div className='dynamic-content'>
                        <img src={publicUrl+'/img/hero.png'} alt='dynamic=content-image'/>
                        <p>We understand that each customer’s needs and budgets are different. That’s why we offer flexible options, working with you to source materials that align with your budget without compromising on quality. Whether you’re looking for premium materials or more cost-effective options, we’re committed to providing durable roofing solutions that fit your financial plan. Our goal is to deliver a dependable roof you can afford and trust.</p>
                    </div>
                );
            case 3:
                return(
                    <div className='dynamic-content'>
                        <img src={publicUrl+'/img/hero.png'} alt='dynamic=content-image'/>
                        <p>As a locally-owned business, we’re proud to serve Houston and the surrounding areas with roofing solutions that protect against Texas’s unique weather conditions. We’re not just building roofs; we’re building relationships and providing ongoing support long after your roof is installed. With HTEX Roofing, you’re getting more than just a service provider—you’re gaining a partner in safeguarding your home.</p>
                    </div>
                );
            case 4:
                return(
                    <div className='dynamic-content'>
                        <img src={publicUrl+'/img/hero.png'} alt='dynamic=content-image'/>
                        <p>With HTEX Roofing, you’re not just investing in a new roof; you’re investing in peace of mind. Our team stands behind each project with dedicated follow-up and warranties to keep your roof strong for years to come. Choose HTEX Roofing for a combination of quality, flexibility, and customer-first service that truly goes above and beyond.</p>
                    </div>
                );
        }
    }

    return(
        <div className='main-content'>
            <div className='wrapper'>
                <div className='nav-column'>
                    <ul>
                        <li className='dyn-btn' onClick={() => setContent(0)}>OUR MISSION</li>
                        <li className='dyn-btn' onClick={() => setContent(1)}>CUSTOMER-CENTRIC APPROACH</li>
                        <li className='dyn-btn' onClick={() => setContent(2)}>FLEXIBLE SOLUTIONS FOR YOUR BUDGET</li>
                        <li className='dyn-btn' onClick={() => setContent(3)}>COMMUNITY COMMITMENT</li>
                        <li className='dyn-btn' onClick={() => setContent(4)}>OUR PROMISE TO YOU</li>
                    </ul>
                </div>
                {dynamic()}
            </div>
        </div>
    );
}

export default About;