import { useEffect, useState } from "react";

const Nav = ({ setPage }) => {
    const publicUrl = process.env.PUBLIC_URL;
    const [menu, setMenu] = useState(false);

    useEffect(() => {
        if(window.innerWidth < 801){
            console.log(menu);
            let bars = document.getElementsByClassName('bars');
            let links = document.getElementsByClassName('links')[0];
            if(!menu){
                bars[0].style.top = '0';
                bars[0].style.transform = 'rotate(0deg)';
                bars[1].style.width = '100%';
                bars[1].style.left = '0';
                bars[2].style.top = 'calc(100% - 4px)';
                bars[2].style.transform = 'rotate(0deg)';
                links.style.top = '-10rem';
            }
            else if(menu){
                bars[0].style.top = 'calc(50% - 2px)';
                bars[0].style.transform = 'rotate(45deg)';
                bars[1].style.width = '0%';
                bars[1].style.left = '50%';
                bars[2].style.transform = 'rotate(-45deg)';
                bars[2].style.top = 'calc(50% - 2px)';
                links.style.top = '5rem';
            }
        }
    }, [menu])

    if(window.innerWidth > 800){
        return(
            <div className='desktop-nav'>
                <img src={publicUrl+'/img/logo-white.png'} alt='logo'/>
                <ul>
                    <li onClick={() => setPage('home')}>HOME</li>
                    <li onClick={() => setPage('about')}>ABOUT US</li>
                    <li onClick={() => setPage('services')}>SERVICES</li>
                    <li onClick={() => setPage('contact')}>CONTACT</li>
                </ul>
            </div>
        );
    }
    else{
        return(
            <>
                <div className='links'>
                    <ul>
                        <li className='unavail'>HOME</li>
                        <p>Our mobile site is currently experiencing technical difficulties.  Please view our website on a computer or tablet to learn more about us.</p>
                    </ul>
                </div>
                <div className='mobile-nav'>
                    <img src={publicUrl+'/img/logo-white.png'} alt='logo'/>
                    <div className='menu-bars' onClick={() => setMenu(!menu)}>
                        <div className='bars'/>
                        <div className='bars'/>
                        <div className='bars'/>
                    </div>
                </div>
            </>
        );
    }
}

export default Nav;