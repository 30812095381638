import { useState } from 'react';
import '../styles/contact.css';

const Contact = () => {
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    return(
        <div className='contact-wrapper'>
            <div className='contact-form'>
                <input type='text' placeholder='Your Name' value={name} onChange={(e) => setName(e.target.value)}/>
                <input type='text' placeholder='Company Name (Optional)' value={company} onChange={(e) => setCompany(e.target.value)}/>
                <input type='text' placeholder='Phone Number' value={phone} onChange={(e) => setPhone(e.target.value)}/>
                <input type='text' placeholder='Email Address' value={email} onChange={(e) => setEmail(e.target.value)}/>
                <textarea value={message} placeholder='How Can We Help?' onChange={(e) => setMessage(e.target.value)}/>
                <button onClick={console.log(name, company, phone, email, message)}>Submit</button>
            </div>
            <div className='contact-info'>
                <h2>Get in touch with us directly!</h2>
                <p>Phone</p>
                <h1>(346) 870 3303</h1>
                <p>Email</p>
                <h1>contact@htexroofing.com</h1>
            </div>
        </div>
    );
}

export default Contact;