import React, { createContext, useContext, useEffect, useState, useRef } from 'react';

const ScrollContext = createContext();

export const useScroll = () => {
    return useContext(ScrollContext);
};

export const ScrollProvider = ({ children }) => {
    const [scrollDirection, setScrollDirection] = useState(null); // null | 'up' | 'down'
    const scrollTimeoutRef = useRef(null); // Ref for storing the timeout ID
    const touchStartRef = useRef(null); // Ref for storing touch start position

    const resetScrollDirection = () => {
        setScrollDirection(null);
    };

    const handleScroll = (event) => {
        const delta = event.deltaY;
        setScrollDirection(delta > 0 ? 'down' : 'up');

        // Clear any existing timeout before setting a new one
        if (scrollTimeoutRef.current) {
            clearTimeout(scrollTimeoutRef.current);
        }

        // Set a timeout to reset scroll direction after 1 second
        scrollTimeoutRef.current = setTimeout(resetScrollDirection, 1000);
    };

    const handleKeyDown = (event) => {
        if (event.key === "ArrowDown") {
            setScrollDirection('down');
        } else if (event.key === "ArrowUp") {
            setScrollDirection('up');
        }

        // Clear any existing timeout before setting a new one
        if (scrollTimeoutRef.current) {
            clearTimeout(scrollTimeoutRef.current);
        }

        // Set a timeout to reset scroll direction after 1 second
        scrollTimeoutRef.current = setTimeout(resetScrollDirection, 1000);
    };

    const handleTouchStart = (event) => {
        if (event.touches.length > 0) {
            touchStartRef.current = event.touches[0].clientY; // Store initial touch position
        }
    };

    const handleTouchEnd = (event) => {
        if (touchStartRef.current !== null && event.changedTouches.length > 0) {
            const touchEnd = event.changedTouches[0].clientY;
            const delta = touchStartRef.current - touchEnd;

            if (delta > 50) { // Swipe up
                setScrollDirection('down');
            } else if (delta < -50) { // Swipe down
                setScrollDirection('up');
            }

            // Clear any existing timeout before setting a new one
            if (scrollTimeoutRef.current) {
                clearTimeout(scrollTimeoutRef.current);
            }

            // Set a timeout to reset scroll direction after 1 second
            scrollTimeoutRef.current = setTimeout(resetScrollDirection, 1000);
            touchStartRef.current = null; // Reset the touch start position
        }
    };

    useEffect(() => {
        window.addEventListener("wheel", handleScroll, { passive: false });
        window.addEventListener("keydown", handleKeyDown);
        window.addEventListener("touchstart", handleTouchStart);
        window.addEventListener("touchend", handleTouchEnd);

        return () => {
            window.removeEventListener("wheel", handleScroll);
            window.removeEventListener("keydown", handleKeyDown);
            window.removeEventListener("touchstart", handleTouchStart);
            window.removeEventListener("touchend", handleTouchEnd);
            // Cleanup timeout on unmount
            if (scrollTimeoutRef.current) {
                clearTimeout(scrollTimeoutRef.current);
            }
        };
    }, []);

    return (
        <ScrollContext.Provider value={scrollDirection}>
            {children}
        </ScrollContext.Provider>
    );
};
